import React, { useContext } from 'react';
import { PageContext } from '@/context/PageContext';

import { SecondaryHero } from '@latitude/hero';
import { Metadata } from '@latitude/metadata';
import { SITE_URL } from '@/utils/constants';

import Layout from '@/components/layout';
import Faq from '@/components/Faq/AccordionSidebarFaq';

import pageData from '@/data/pages/credit-cards/twenty-eight-degree.json';
import DegreesCardMicrositeHeader from './_28d-microsite-header';

const Faqs = ({ location }) => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */
  return (
    <Layout location={location}>
      <Metadata
        title={`FAQs | 28 Degrees card`}
        description={`Learn all the handy hints, tips, and answers for everything you wanted to know about your 28 Degrees Card`}
        keywords="FAQ"
        canonical={`${SITE_URL}${location.pathname}`}
      />
      <DegreesCardMicrositeHeader data={state.secondaryNavData} />
      <SecondaryHero
        style={'padding-top: 80px;'}
        heading="Frequently Asked Questions"
        subHeading="Got a burning question? You may find your answer here."
      />

      <Faq data={pageData.faqs} />
    </Layout>
  );
};

export default Faqs;
